.ReactModal__Overlay {
    z-index: 9;
    background-color: rgb(0 0 0 / 75%) !important;
}

.tabs-selection.asset__tabs ul {
    display: flex;
    border-bottom: 1px solid #222227;
}

.main__tabs.tabs-selection ul:not(.filter__checkboxes),
.profile__tabs.tabs-selection ul:not(.filter__checkboxes) {
    display: flex;
}

.tabs-selection ul li a {
    cursor: pointer;
}

.text-dark {
    color: #222227;
}

.rounded-3 {
    border-radius: 10px;
}

.img-container img {
    object-fit: cover;
}

.search-results-floating {
    position: absolute;
    top: 60px;
    left: 0;
    background: #ffffff;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #eff4f7;
    width: 100%;
    height: 60vh;
    overflow-y: auto;
    display: none;
}

.search-results-floating hr {
    border: 1px solid #eff4f7;
}

.search-results-floating.show {
    display: block;
}

.text-secondary {
    color: #666666;
}

.fw-bold {
    font-weight: bold;
}

.img-fluid {
    object-fit: cover;
}

.hide {
    display: none;
}

.Logo-styled {
    color: #380036;
    font-size: 35px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    background: linear-gradient(330deg, #0cbaba 0%, #380036 74%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    font-family: 'Inter';
}

.Logo-styled.white {
    background-color: #eec0c6;
    background-image: linear-gradient(315deg, #eec0c6 0%, #7ee8fa 74%);
}

hr {
    box-sizing: inherit;
    height: auto !important;
    overflow: auto !important;
    border-top: 1px solid #dddddd;
    width: 100%;
}

.type-of-listing-btn {
    background: #eff4f7;
    padding: 5px 14px;
    border-radius: 30px;
    margin: 0 10px;
    color: black;
}

.type-of-listing-btn.active {
    background: #380036;
    color: white;
}

.type-of-listing-btn:hover {
    background: #0cbaba;
    color: white;
}

.author__follow.followed {
    background-color: #0cbaba;
}

.Logo-styled {
    background: #380036;
    background: -webkit-linear-gradient(to right, #380036 0%, #0BA6A6 50%, #7A0D46 100%);
    background: -moz-linear-gradient(to right, #380036 0%, #0BA6A6 50%, #7A0D46 100%);
    background: linear-gradient(to right, #380036 0%, #0BA6A6 50%, #7A0D46 100%);
    background-size: 200% auto;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: rainbowlight 3s infinite linear !important;
    -moz-animation: rainbowlight 3s infinite linear !important;
    -ms-animation: rainbowlight 3s infinite linear !important;
    -o-animation: rainbowlight 3s infinite linear !important;
    animation: rainbowlight 3s infinite linear !important;
    -webkit-animation-play-state: running !important;
    -moz-animation-play-state: running !important;
    -o-animation-play-state: running !important;
    animation-play-state: running !important;
}

.rainbow-animated {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    color: white !important;
    -webkit-animation: rainbowlight 5s infinite !important;
    -moz-animation: rainbowlight 5s infinite !important;
    -ms-animation: rainbowlight 5s infinite !important;
    -o-animation: rainbowlight 5s infinite !important;
    animation: rainbowlight 5s infinite !important;
    -webkit-animation-play-state: running !important;
    -moz-animation-play-state: running !important;
    -o-animation-play-state: running !important;
    animation-play-state: running !important;
}

.loading-bigger-loading-screen {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bg-semilight {
    background-color: #eff4f7;
}


video:not(.search-videos) {
    width: 100%;
    min-height: 300px;
}

.floating-text-loop {
    font-size: 12px;
    background: #3800366b;
    color: white;
    padding: 2px 10px;
    position: absolute;
    top: 16px;
    left: 8px;
}


.activity video {
    min-height: auto;
}

.swal-icon.swal-icon--custom img {
    max-width: 80px;
}

a.history-tab-profile {
    height: auto !important;
    display: inline-block !important;
}

.collections-wrapper .card__author a {
    background-color: transparent;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

span.category-balloon {
    background-color: #380036;
    margin: 3px;
    padding: 4px 10px;
    border-radius: 15px;
    font-size: 14px;
    font-weight: bold;
}

span.category-balloon:hover {
    background-color: #0cbaba;
}

span.category-balloon a {
    color: #ffffff;
    text-decoration: none !important;
}

.card span.category-balloon a {
    font-size: 12px;
    font-weight: normal;
    background: none;
    padding: 0!important;
    margin:0!important;
    text-decoration: underline!important;
}

.card span.category-balloon {
    background: none;
    padding: 0;
    margin:1px 3px;
}

.input-group input {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/* Animations */

@keyframes rainbowlight {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@-webkit-keyframes zigzagPlay {
    0% {
        background-position: 0;
    }

    100% {
        background-position: -96px;
    }
}

@-moz-keyframes zigzagPlay {
    0% {
        background-position: 0;
    }

    100% {
        background-position: -96px;
    }
}

@-ms-keyframes zigzagPlay {
    0% {
        background-position: 0;
    }

    100% {
        background-position: -96px;
    }
}

@-o-keyframes zigzagPlay {
    0% {
        background-position: 0;
    }

    100% {
        background-position: -96px;
    }
}

@keyframes zigzagPlay {
    0% {
        background-position: 0;
    }

    100% {
        background-position: -96px;
    }
}

.desktop-view {
    display: block;
}

.mobile-view {
    display: none;
}

/* Responsiveness */

@media only screen and (max-width: 1200px) {
    .desktop-view {
        display: none;
    }

    .mobile-view {
        display: block;
    }
}

@media only screen and (max-width: 992px) {

    .main__tabs.tabs-selection ul:not(.filter__checkboxes),
    .profile__tabs.tabs-selection ul:not(.filter__checkboxes) {
        display: block;
    }

    .profile__tabs li {
        display: inline-block !important;
        width: auto !important;
    }

    .profile__tabs a.nav-link {
        align-items: center;
        height: 70px;
        height: auto !important;
        margin: 10px 0;
        padding: 7px 10px;
        border-radius: 10px;
    }

    .profile__tabs a.nav-link.active {
        background: #380036;
        color: white;
    }

    .profile__tabs a.nav-link.active::before {
        display: none;
    }
}